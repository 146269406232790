import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["contactFirstName", "contactLastName"]

  connect() {
    console.log("Hello, Stimulus!", this.element);

    this.fields().forEach((field) => {
      if(field) {
        field.style.width = `${field.value.length}ch`;

        field.addEventListener("input", this.updateFieldWidth.bind(this));
      }
    })
  }

  fields() {
    const fields = ["contactFirstName", "contactLastName"];

    return fields.map(field => {
      try {
        return this[`${field}Target`];
      } catch {
        return null
      }
    }).filter(field => field)
  }

  updateFieldWidth(e) {
    e.target.style.width = `${e.target.value.length}ch`;

    return e
  }
}
