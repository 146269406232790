import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dropZone", "fileField", "logoContainer", "logo", "uploadIcon"]
  static values = { url: String }

  connect() {
    console.log("Hello, Stimulus!", this.element);

    this.start()

    this.dropZoneTarget.addEventListener("dragover", this.preventDefaults, false)
    this.dropZoneTarget.addEventListener("drop", this.handleDrop.bind(this), false)
    this.dropZoneTarget.addEventListener("click", this.openFilePicker.bind(this), false)
    this.fileFieldTarget.addEventListener("change", this.onChange.bind(this), false)
  }

  start(){
    if (this.urlValue) {
      this.logoTarget.src = this.urlValue

      this.displayLogo()
    }
  }

  displayLogo() {
    if(!this.logoContainerTarget.classList.contains('hidden')) return

    this.logoContainerTarget.classList.remove('hidden')
    this.uploadIconTarget.classList.add('hidden')
  }

  preventDefaults(e) {
    e.preventDefault()
    e.stopPropagation()
  }

  openFilePicker(e) {
    this.preventDefaults(e)

    this.fileFieldTarget.click();
  }

  onChange(e) {
    this.preventDefaults(e)

    this.logoTarget.src = URL.createObjectURL(e.target.files[0])

    this.displayLogo()
    
    return e
  }

  handleDrop(e) {
    this.preventDefaults(e)

    this.fileFieldTarget.files = e.dataTransfer.files
    this.logoTarget.src = URL.createObjectURL(e.dataTransfer.files[0])

    this.displayLogo()
  }
}
