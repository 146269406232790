import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="switch-text"
export default class extends Controller {
  static targets = ['checkbox', 'text'];
  static values = {
    on: { type: String, default: 'Yes' },
    off: { type: String, default: 'No' }
  }

  toggle() {
    if (this.checkboxTarget.checked) {
      this.textTarget.textContent = this.onValue;
    } else {
      this.textTarget.textContent = this.offValue;
    }
  }
}
