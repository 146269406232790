import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    console.log("Hello, Stimulus!", this.element);

    // Loading the vertical line when the page is loaded
    document.addEventListener("DOMContentLoaded", () => this.displayVerticalLine());
  }

  updateLinePosition() {
    const item1Rect = this.item1.getBoundingClientRect();
    const item2Rect = this.item2.getBoundingClientRect();

    const containerRect = document.querySelector('.container').getBoundingClientRect();

    const topPosition = item1Rect.bottom - containerRect.top;
    const bottomPosition = item2Rect.top - containerRect.top;

    this.verticalLine.style.top = `${topPosition}px`;
    this.verticalLine.style.height = `${bottomPosition - topPosition}px`;
    this.verticalLine.style.left = `${(item1Rect.left + item1Rect.width / 2) - containerRect.left}px`;
  }

  displayVerticalLine() {
    this.item1 = document.getElementById('item1');
    this.item2 = document.getElementById('item2');

    if (this.item1 && this.item2) {
        this.verticalLine = document.createElement('div');
        this.verticalLine.classList.add('vertical-line');

        document.querySelector('.container').appendChild(this.verticalLine);

        window.addEventListener('resize', () => this.updateLinePosition());
        window.addEventListener('scroll', () => this.updateLinePosition());

        this.updateLinePosition();
    }
  }
}
