import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['container'];

  connect() {
    if (this.hasContainerTarget) {
      this.containerTarget.querySelectorAll('a').forEach(link => link.setAttribute('target', '_blank'));
    }
  }
}
