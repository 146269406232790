import { Controller } from "@hotwired/stimulus"
import intlTelInput from 'intl-tel-input';

const utilsScriptSrc = 'https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/js/utils.js'

// Connects to data-controller="phone-input"
export default class extends Controller {
  static targets = ['input', 'hidden'];
  static values = {
    country: { type: String, default: 'US' },
    switch: { type: Boolean, default: true }
  }

  connect() {
    this.loadUtilsScript(() => {
      this.initializeIntlTelInput();
      this.initializeFormatter();
    });
  }

  loadUtilsScript(callback) {
    const script = document.createElement('script');
    script.src = utilsScriptSrc;
    script.onload = callback;
    document.head.appendChild(script);
  }

  initializeIntlTelInput() {
    this.iti = intlTelInput(this.inputTarget, {
      allowDropdown: this.switchValue,
      customContainer: 'w-100',
      initialCountry: this.countryValue,
      preferredCountries: ['us', 'ca', 'gb', 'mx', 'br'],
      separateDialCode: true,
      utilsScript: utilsScriptSrc,
    })


    this.inputTarget.addEventListener("countrychange", () => this.handleChangePattern());
  }

  initializeFormatter() {
    const initial_country = this.iti.getSelectedCountryData().iso2;
    const pattern = this.getPatternForCountry(initial_country);

    this.formatter = new Formatter(this.inputTarget, {
      'pattern': pattern
    });
  }

  getPatternForCountry(countryCode) {
    try {
      const numberFormat = intlTelInputUtils.numberFormat;
      // Get an example number for the country
      const exampleNumber = intlTelInputUtils.getExampleNumber(countryCode, true, numberFormat.INTERNATIONAL);
      // Extract the format from the example number
      const pattern = this.extractPatternFromExampleNumber(exampleNumber);
      return pattern;
    } catch (error) {
      console.log("Error fetching pattern for country: ", countryCode)
      console.error("Error: ", error);

      if (countryCode == 'us') {
        return '({{9}}{{9}}{{9}}) {{9}}{{9}}{{9}}-{{9}}{{9}}{{9}}{{9}}';
      } else {
        return '{{9999999999999999999999}}'; // Default pattern
      }
    }
  }

  extractPatternFromExampleNumber(exampleNumber) {
    // Replace each digit in the example number with '{{9}}'
    return exampleNumber.replace(/\d/g, '{{9}}');
  }


  updateCountry({ detail: { content }}) {
    const newCountry = content.pop();
    this.iti.setCountry(newCountry);
  }

  validate() {
    if (this.inputTarget.value.length == 0 ) {
      this.inputTarget.setCustomValidity('')
      this.hiddenTarget.value = '';
      return;
    }

    if (!this.iti.isPossibleNumber()) {
      const error = this.iti.getValidationError();
      let msg = '';
      console.log("Current number: ", this.iti.getNumber())
      console.log("Error: ", error)

      switch(error) {
        case 1:
          msg = "Phone number has invalid country code"
          break;
        case 2:
          msg = "Phone number is too short"
          break;
        case 3:
          msg = "Phone number is too long"
          break;
        case 4:
          msg = "Phone number is local"
          break;
        case 5:
          msg = "Phone number has invalid length"
          break;
      }
      this.inputTarget.setCustomValidity(msg);
    } else {
      this.inputTarget.setCustomValidity('')
      this.hiddenTarget.value = this.iti.getNumber();
    }

    this.inputTarget.checkValidity();
  }

  handleChangePattern() {
    const { iso2: prefix } = this.iti.getSelectedCountryData();
    const newPattern = this.getPatternForCountry(prefix);

    this.formatter.resetPattern(newPattern);
  }
}
