import {Controller} from "@hotwired/stimulus"

const backElement = document.getElementById('confirmExitModal');
const confirmElement = document.getElementById('confirmAnnouncementModal');
const contactEditElement = document.getElementById('editMediaContactModal');
const saveDraftElement = document.getElementById('saveModal');

export default class extends Controller {
  connect() {
    this.lastClicked = null;
    document.addEventListener('click', (e) => { this.lastClicked = e.target });
  }

  stopClose(event) {
    if (
      backElement?.classList.contains('show')
      || confirmElement?.classList.contains('show')
      || contactEditElement?.classList.contains('show')
      || saveDraftElement?.classList.contains('show')
      || this.lastClicked?.dataset?.name == 'save_draft'
      || this.lastClicked?.dataset?.name == 'switch_org'
      || this.lastClicked?.dataset?.name == 'save_scheduled_press_release'
    ) {
      return;
    }
    event.preventDefault();
    event.returnValue = 'Are you sure you want to leave? Changes may not be saved.';
  }
}
