import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    persisted: { type: Boolean, default: false },
  }
    connect() {
        const previewModalTrigger = document.getElementById('preview-modal')
        if (this.persistedValue) {
          previewModalTrigger.classList.remove('d-none')
      }
    }

}
