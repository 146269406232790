import "@hotwired/turbo-rails"
import "./controllers"
import "@rails/actiontext"
import "trix"
import "@rails/request.js"

import "swiper"
import "fslightbox"
import "sortablejs"
import * as bootstrap from "bootstrap"
import Dropzone from "@crespire/dropzone"


//= require jquery3
//= require popper
//= require bootstrap-sprockets

// Disable turbo by default
Turbo.session.drive = false;

// Enable Bootstrap Tooltips
const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))

// Dropzone configuration for Logo Uploads
Dropzone.autoDiscover = false;

// Trix configurations
// Trix url placeholder
Trix.config.lang.urlPlaceholder = 'https://example.com'

// Trix fix toolbar
document.addEventListener('trix-initialize', () => {
  const button = document.querySelector("input[value='Link']");
  button.insertAdjacentHTML('afterend', "&nbsp;")
}, { once: true });

// Trix file accept
window.addEventListener("trix-file-accept", function(event) {
  event.preventDefault();
  return;

  /* Don't accept any files
    const acceptedTypes = ['image/jpeg', 'image/png']
    const maxFileSize = (1024 * 1024) * 5 // 5MB

    if (!acceptedTypes.includes(event.file.type)) {
      event.preventDefault()
      alert(`Supported file types: ${acceptedTypes.join(', ').replaceAll('image/', '')}`)
    }

    if (event.file.size > maxFileSize) {
      event.preventDefault()
      alert("Attachments must be 5MB or smaller.")
    }
  */
});

// Trix prevent copy/paste file
window.addEventListener("trix-attachment-add", function(event) {
  if (!event.attachment.file) {
    event.attachment.remove()
  }
})

// Load facebook SDK
window.fbAsyncInit = function() {
    FB.init({
        appId            : process.env.FACEBOOK_APP_ID,
        autoLogAppEvents : true,
        xfbml            : true,
        version          : 'v16.0'
    });
};
