// app/javascript/controllers/share_actions_controller.js

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="share-actions"
export default class extends Controller {
  #isShareButtonsDisplayed = false
  #labels = ["Share", "Cancel"]
  #anchorsContainer = null

  static targets = [ "share", 'view' ]

  connect() {
    const anchorsContainer = this.element.querySelector("#press_release_social_media")

    this.#anchorsContainer = anchorsContainer
  }

  onToggle() {
    const newState = !this.#isShareButtonsDisplayed
    const methods = ["add", "remove"]

    if(this.#anchorsContainer) this.addOrRemoveDisplayNone(this.#anchorsContainer, methods[Number(newState)])
    this.addOrRemoveDisplayNone(this.viewTarget, methods[Number(this.#isShareButtonsDisplayed)])

    this.addOrRemoveDisplayNone(this.shareTarget, methods[Number(newState)], 'btn-primary-custom-600')
    this.shareTarget.innerText = this.#labels[Number(newState)]
    this.#isShareButtonsDisplayed = newState
  }

  addOrRemoveDisplayNone(element, method, className = 'd-none') {
    element.classList[method](className)
  }
}
