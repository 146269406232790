import { Controller } from "@hotwired/stimulus"
import * as bootstrap from 'bootstrap';
import intlTelInput from 'intl-tel-input';

// Connects to data-controller="contact-modal-form"
export default class extends Controller {
  static targets = ['form', 'input']

  connect() {
    this.modal = bootstrap.Modal.getOrCreateInstance(this.element);
    this.iti = intlTelInput(this.inputTarget);
    this.phoneInput = this.element.querySelector('#media_contact_phone_number')
    this.restoreValue = this.phoneInput.value
    
    this.element.addEventListener('hide.bs.modal', event => {
      if (!this.formTarget.checkValidity()) {
        return event.preventDefault();
      }
    });

    this.element.querySelector('[data-btn-intent="cancel"]').addEventListener('click', event => {
      this.iti.setNumber(this.restoreValue);
      this.inputTarget.setCustomValidity('');
      this.modal.hide();
    })

    this.formTarget.addEventListener('submit', event => {
      this.restoreValue = this.phoneInput.value
    })
  }
}
