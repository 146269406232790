// app/javascript/controllers/edit_profile_actions_controller.js

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="edit-profile-actions"
export default class extends Controller {
  #formChanged = false
  static targets = ['anchor'];
  static values = {
    confirmationMessage: String,
  };

  connect() {
    const forms = this.element.querySelectorAll("form")
    forms.forEach(form => form.addEventListener('submit', () => this.#formChanged = false))

    this.detectFormChanges(forms);
    this.anchorTargets.forEach(anchor => anchor.addEventListener('click', event => this.toggle(event)))
  }

  detectFormChanges(elements) {
    elements.forEach(element => {
      const fields = element.querySelectorAll("input.form-control")

      fields.forEach(field => field.addEventListener('input', () => this.#formChanged = true))
    })

    window.addEventListener('beforeunload', (event) => {
      if (this.#formChanged) {
        event.preventDefault();
        event.returnValue = this.confirmationMessageValue;
      }
    });
  }

  toggle(event) {
    event.preventDefault()

    const checkIsCollapsed = /^true$/i
    const checkIsPasswordAccordion = /password/i

    if(checkIsCollapsed.test(event.target.ariaExpanded)){
      event.target.textContent = "Cancel"
      event.target.className = "text-body-tertiary"
    } else {
      event.target.textContent = checkIsPasswordAccordion.test(event.target.hash) ? "Change" : "Edit"
      event.target.className = ""
    }
  }
}
