import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = [ "input" ]

    connect() {
        this.resize()
    }

    resize() {
        this.inputTarget.style.width = '150px'
        this.inputTarget.style.width = `${this.inputTarget.scrollWidth}px`
    }
}
