import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["editable", "hidden"];

  connect() {
  }

  formatPaste(event) {
    event.preventDefault();
    let paste = (event.clipboardData || window.clipboardData).getData('text');
    this.editableTarget.textContent = paste.trim();
  }

  update() {
    this.hiddenTarget.value = this.editableTarget.textContent;
  }
}
