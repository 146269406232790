import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="onboarding-description"
export default class extends Controller {
  static targets = ['form', 'header', 'choice', 'example']
  static classes = ['show', 'hide']
  static values = {
    error: Boolean
  }

  connect() {
    if (this.errorValue == true) {
      this.showForm();
    }
  }

  showForm() {
    if (this.formTarget.classList.contains('d-none')) {
      document.getElementById('onboarding-backlink')?.setAttribute('href', 'javascript:window.location.reload();');
      this.headerTarget.textContent = "Paste your company's boilerplate below"
      this.choiceTarget.classList.add(this.hideClass)
      this.exampleTarget.classList.add(this.hideClass)
      this.formTarget.classList.remove(this.hideClass)
      this.formTarget.classList.add(this.showClass)
    }
  }
}
