import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="onboarding-agency-info"
export default class extends Controller {
  static classes = ['hide', 'show']
  static targets = ['url', 'name', 'select', 'source', 'checkbox']

  connect() {
    console.log('Connected to onboarding-agency-info')
    if (this.hasSelectTarget && this.selectTarget.value == 'agency_or_consultant') {
      this.sourceTarget.classList.add(this.showClass);
      this.sourceTarget.classList.remove(this.hideClass);
      this.nameTarget.setAttribute('required', true);
      this.urlTarget.setAttribute('required', true);
      this.checkboxTarget.setAttribute('required', true);
      this.checkboxTarget.value = true;
      this.checkboxTarget.checked = true;
    }
  }

  update(event) {
    if (event.target.value == 'agency_or_consultant') {
      this.sourceTarget.classList.add(this.showClass);
      this.sourceTarget.classList.remove(this.hideClass);
      this.nameTarget.setAttribute('required', true);
      this.urlTarget.setAttribute('required', true);
      this.checkboxTarget.setAttribute('required', true);
      this.checkboxTarget.value = true;
      this.checkboxTarget.checked = true;
    } else {
      this.sourceTarget.classList.add(this.hideClass);
      this.sourceTarget.classList.remove(this.showClass);
      this.nameTarget.removeAttribute('required');
      this.urlTarget.removeAttribute('required');
      this.checkboxTarget.removeAttribute('required');
      this.checkboxTarget.value = false;
      this.checkboxTarget.checked = false;
      this.urlTarget.value = null;
      this.nameTarget.value = null;
    }
  }
}
