import { Controller } from "@hotwired/stimulus"
import * as bootstrap from 'bootstrap'
import Dropzone from '@crespire/dropzone'

export default class extends Controller {
  connect() {
    try {
      this.dropzone = new Dropzone('#editor-images', this.dzOptions)
    } catch {
      console.error(`Error loading ${this.element.id} dropzone`)
    }

    let tokenInput = this.element.querySelector("[name='authenticity_token']");

    if (!tokenInput) {
      // Re-add CSRF token, turbo renders don't have access to session
      // Optional chaning because, in test, csrf token will be null
      const csrfToken = document.querySelector("[name='csrf-token']")?.content
      tokenInput = document.createElement('input');
      tokenInput.setAttribute('type', 'hidden');
      tokenInput.setAttribute('name', 'authenticity_token');
      tokenInput.setAttribute('autocomplete', 'off');
      tokenInput.setAttribute('value', csrfToken);
      this.element.querySelector('#editor-images').prepend(tokenInput);
    }
  }

  get dzOptions() {
    return {
      paramName: "press_release_images",
      uploadMultiple: true,
      maxFilesize: 20, // MB
      maxFiles: 5,
      parallelUploads: 5,
      acceptedFiles: "image/jpeg, image/png",
      resizeMimeType: 'image/jpeg',
      resizeHeight: 1000,
      hiddenInputContainer: this.element,
      disablePreviews: true,
      renameFile: function (file) {
        return file.name.split('.').slice(0, -1).concat('jpg').join('.')
      },
      init: function () {
        this.on('addedfile', (file) => {
          const target = this.element.querySelector('div.d-flex');
          target.innerHTML = `
              <div class="spinner-border text-primary-600" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            `
        });

        // Get rid of the default message div
        const msg = this.element.querySelector('.dz-default');
        msg.remove();
      },
      queuecomplete: function(_file, _response) {
        const target = this.element.querySelector('div.d-flex');
        const imageErrors = document.getElementById('image-management-error');
        const fileCount = this.files.length;
        const rejectedFiles = this.getRejectedFiles();

        if (fileCount > 1 && rejectedFiles.length > 0) {
          let names = new Set;
          rejectedFiles.forEach((currentFile) => names.add(currentFile.name))
          imageErrors.appendChild(document.createTextNode(Array.from(names).join(', ')));
          imageErrors.classList.add('d-block');
          imageErrors.classList.remove('d-none');
          setTimeout(() => {
            const imageErrors = document.getElementById('image-management-error');
            imageErrors.classList.remove('d-block');
            imageErrors.classList.add('d-none');
            imageErrors.textContent = '';
          }, 15000)
        } else if (fileCount == 1 && rejectedFiles.length == 1) {
          const file = rejectedFiles.pop();
          target.innerHTML = `
              <p class="text-center">${file.name} is too large (${(file.size / 1024 / 1024).toFixed(1)}MB)<br>The maximium is 20MB<br>Please check your files and try again</p>
            `
          target.classList.add('text-error-500');
          this.removeAllFiles();
          return;
        }

        const uploadEl = document.getElementById('uploadImagesModal');
        const uploadModal = bootstrap.Modal.getOrCreateInstance(uploadEl);
        const manageEl = document.getElementById('imageManagement');
        const manageModal = bootstrap.Modal.getOrCreateInstance(manageEl);
        uploadModal.hide();
        manageModal.show();
      },
    }
  }
}
