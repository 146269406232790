import { Controller } from "@hotwired/stimulus"
import * as bootstrap from 'bootstrap'

export default class extends Controller {
  static values = {
    name: String,
  }
  static targets = ['cancel'];

  connect() {
    this.form = document.getElementById(`${this.nameValue}_form`);
    this.formModal = document.getElementById(`${this.nameValue}_form_page_one`);
    this.modal = bootstrap.Modal.getOrCreateInstance(this.formModal);
    this.skip = false;

    if (this.hasCancelTarget) {
      this.cancelTarget.addEventListener('click', event => {
        let conf = confirm('Are you sure? You will lose any new information you entered.');
        if (conf) {
          this.skip = true;
          this.modal.hide();
          this.form.reset();
          this.skip = false;
        }
      })
    }

    // Prevent close unless valid
    this.formModal.addEventListener('hide.bs.modal', event => {
      if (!this.skip && !this.form.reportValidity()) {
        return event.preventDefault();
      }
    });
  }

  validate(event) {
    const element = event.target;

    if (element.checkValidity()) {
      element.removeAttribute('aria-invalid');
      element.parentElement.classList.remove('field_with_errors');
    } else {
      element.setAttribute('aria-invalid', true);
      element.parentElement.classList.add('field_with_errors');
    }
  }

  validateURL(event) {
    const element = event.target;

    if (!element.value.includes('http')) {
      element.value = "https://" + element.value;
    }

    this.validate(event);
  }

  validateLocation(event) {
    const element = event.target || event.relatedTarget;

    if (element.checkValidity()) {
      element.removeAttribute('aria-invalid');
      element.parentElement.classList.remove('field_with_errors');
    } else {
      element.setAttribute('aria-invalid', true);
      element.parentElement.classList.add('field_with_errors');
    }
  }
}
