import { Controller } from "@hotwired/stimulus"
import * as bootstrap from 'bootstrap'

// Connects to data-controller="quote"
export default class extends Controller {
  static targets = [ 'checkbox', 'firstName', 'lastName', 'title', 'form'];

  connect() {
    this.collapse = bootstrap.Collapse.getOrCreateInstance(this.formTarget);
    this.toggle();
  }

  toggle() {
    if (this.checkboxTarget.checked) {
      this.collapse.show();
      this._setRequired();
    } else {
      this.collapse.hide();
      this._removeRequired();
    }
  }

  _setRequired() {
    this.firstNameTarget.setAttribute('required', true);
    this.lastNameTarget.setAttribute('required', true);
    this.titleTarget.setAttribute('required', true);
  }

  _removeRequired() {
    this.firstNameTarget.removeAttribute('required');
    this.lastNameTarget.removeAttribute('required');
    this.titleTarget.removeAttribute('required');
  }
}
