import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="onboarding-contact"
export default class extends Controller {
  static targets = ['form', 'firstName', 'lastName', 'email', 'phone', 'phoneInput']
  static values = {
    firstName: { type: String, default: '' },
    lastName: { type: String, default: '' },
    email: { type: String, default: '' },
    phone: { type: String, default: '' },
    userFirstName: String,
    userLastName: String,
    userEmail: String,
    userPhone: String,
    samePerson: Boolean
  }
  static classes = ['hide', 'show']

  change(event) {
    if (event.target.value == 'me') {
      this.formTarget.classList.remove(this.showClass);
      this.formTarget.classList.add(this.hideClass);
      this.firstNameTarget.value = this.userFirstNameValue;
      this.lastNameTarget.value = this.userLastNameValue;
      this.emailTarget.value = this.userEmailValue;
      this.phoneTarget.value = this.userPhoneValue;
      this.phoneInputTarget.value = this.userPhoneValue;
    } else {
      this.formTarget.classList.remove(this.hideClass);
      this.formTarget.classList.add(this.showClass);
      this.firstNameTarget.value = this.samePersonValue ? '' : this.firstNameValue;
      this.lastNameTarget.value = this.samePersonValue ? '' : this.lastNameValue
      this.emailTarget.value = this.samePersonValue ? '' : this.emailValue
      this.phoneTarget.value = this.samePersonValue ? '' : this.phoneValue
      this.phoneInputTarget.value = this.samePersonValue ? '' : this.phoneValue
    }
  }
}
