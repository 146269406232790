import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        // strip out all HTML tags except <a>, <br>, and <p>
        // add rel="nofollow" to <a> tags
        // Replace &nbsp; within <div> with <br>
        document.addEventListener('trix-before-paste', function (e) {
            if (e.paste.hasOwnProperty('html')) {
                let div = document.createElement("div");
                div.innerHTML = e.paste.html;

                // Convert <h> tags to <p> tags.
                ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].forEach(tag => {
                    Array.from(div.getElementsByTagName(tag)).forEach(function(node) {
                        let p = document.createElement("p");
                        p.innerHTML = node.innerHTML;
                        node.parentNode.replaceChild(p, node);
                    });
                });

                // Add rel="nofollow" to <a> tags.
                Array.from(div.getElementsByTagName("a")).forEach(function(node) {
                    node.setAttribute("rel", "nofollow");
                });

                // Detect &nbsp; alone within divs and replace it with line breaks.
                Array.from(div.getElementsByTagName("div")).forEach(function(node) {
                    if (node.innerHTML.trim() === '&nbsp;') {
                        node.innerHTML = '<br>';
                    }
                });

                let tagsToKeep = ['A', 'BR', 'P'];

                // Remove unwanted tags.
                Array.from(div.getElementsByTagName("*")).forEach(function(node) {
                    if (!tagsToKeep.includes(node.tagName.toUpperCase())) {
                        while (node.firstChild) {
                            node.parentNode.insertBefore(node.firstChild, node);
                        }
                        node.parentNode.removeChild(node);
                    }
                });

                e.paste.html = div.innerHTML;
            }
        });
    }
}
