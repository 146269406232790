import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ["scheduleDate", "option", "scheduledAt", "dateTimeError"];

    connect() {
        this.toggleDateField();
        this.addScheduledAtListener();
        setTimeout(() => this.validateScheduledAt(), 500); // validate after 0.5s to allow time for page to load
    }

    addScheduledAtListener() {
        if (this.hasScheduledAtTarget) {
            this.scheduledAtTarget.addEventListener('change', () => this.validateScheduledAt());
        }
    }

    toggleDateField() {
        const isLaterSelected = this.optionTargets.find(option => option.value === 'later' && option.checked);

        if (isLaterSelected) {
            this.scheduleDateTarget.style.display = 'block';
        } else {
            this.scheduleDateTarget.style.display = 'none';
            this.clearScheduledAt();
        }
    }

    clearScheduledAt() {
        if (this.hasScheduledAtTarget) {
            this.scheduledAtTarget.value = '';
        }
        this.hideDateTimeError();
    }

    validateScheduledAt() {
        const minDateTime = new Date(this.scheduledAtTarget.getAttribute('min'));
        const selectedDateTime = new Date(this.scheduledAtTarget.value);

        if (selectedDateTime < minDateTime) {
            const formatDateAndTime = (date) => {
                const userLocale = Intl.DateTimeFormat().resolvedOptions().locale;

                if (userLocale === 'en-US') {
                    // Custom format for US
                    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
                    const timeOptions = { hour: 'numeric', minute: '2-digit', hour12: true };

                    const formattedDate = date.toLocaleString('en-US', dateOptions);
                    const formattedTime = date.toLocaleString('en-US', timeOptions);

                    return `${formattedTime} on ${formattedDate}`;
                } else {
                    // Standard format for other locales
                    return date.toLocaleString();
                }
            };

            const minDateTime = new Date(this.scheduledAtTarget.getAttribute('min'));
            const formattedDateTime = formatDateAndTime(minDateTime);
            this.showDateTimeError("Scheduled date and time must be later than " + formattedDateTime);
        } else {
            this.hideDateTimeError();
        }
    }

    showDateTimeError(message) {
        if (this.hasDateTimeErrorTarget) {
            this.dateTimeErrorTarget.textContent = message;
            this.dateTimeErrorTarget.style.display = 'block';
        }
    }

    hideDateTimeError() {
        if (this.hasDateTimeErrorTarget) {
            this.dateTimeErrorTarget.textContent = '';
            this.dateTimeErrorTarget.style.display = 'none';
        }
    }
}
