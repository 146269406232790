import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'address',
    'city',
    'state'
  ]

  static values = {
    country: String,
  }

  initialize() {
    this.placeChanged = this.placeChanged.bind(this);
  }

  connect() {
    console.log('Connected to places controller')
    window.addEventListener('load', (event) => { initAutocomplete(); });

    window.initAutocomplete = function () {
      const event = new Event('google-maps-callback', {
        bubbles: true,
        cancelable: true,
      })
      window.dispatchEvent(event)
    }
    if (typeof google !== 'undefined') {
      this.initAutocomplete()
    }
  }

  initAutocomplete() {
    const restriction = this.countryValue.length > 0 ? [`${this.countryValue}`] : ['us']
    this.autocomplete = new google.maps.places.Autocomplete(this.addressTarget)
    this.autocomplete.addListener('place_changed', this.placeChanged);
    this.autocomplete.setFields(['address_components']);
    this.autocomplete.setTypes(['(cities)']);
    this.autocomplete.setComponentRestrictions({
      country: restriction
    });
  }

  updateRestrictions({ detail: { content } }) {
    let restriction = content.filter((entry) => entry.length > 0)
    if (restriction.length == 0) { // Only fallback if blank is selected
      restriction = this.countryValue.length > 0 ? [`${this.countryValue}`] : ['us']
    }
    this.autocomplete.setComponentRestrictions({
      country: restriction
    })
  }

  placeChanged() {
    this.place = this.autocomplete.getPlace()
    const addressComponents = this.place.address_components

    if (addressComponents !== undefined) {
      const formattedAddress = this.#formatAddressComponents(addressComponents)
      this.setAddressComponents(formattedAddress)
    }
  }

  setAddressComponents(address) {
    console.log(address)
    if (this.hasStateTarget) { this.stateTarget.value = address.administrative_area_level_1 || '' }
    // Hardcoded for San Francisco given the Google Maps API returns 'SF' for the locality.
    if (address.locality === 'SF'){ address.locality = 'San Francisco' }
    if (this.hasCityTarget) { this.cityTarget.value = address.locality || '' }

    this.addressTarget.value = this.addressTarget.value.replace(/,\s[^,]*$/, '');
  }

  forceFill(event) {
    const abbreviated_state_regex = /, [A-Z]{2}$/;
    this.addressTarget.value = this.addressTarget.value.replace(/,\s[^,]*$/, '');

    if (this.addressTarget.checkValidity() && this.addressTarget.length > 3) {
      this.dispatch('forceFill', { target: this.addressTarget });
      return;
    }

    const autofills = Array.from(document.querySelectorAll('.pac-container.pac-logo'));
    const result = autofills.filter(el => el.children.length > 0).pop();

    if (result) {
      const city = result.children[0].children[1].textContent;
      const area = result.children[0].children[2].textContent;
      const state = area.split(', ')[0];
      const country = area.split(', ')[1];
      this.addressTarget.value = `${city}, ${state}`
      this.stateTarget.value = state;
      this.cityTarget.value = city;
    }
    this.dispatch('forceFill', { target: this.addressTarget })
  }

  preventSubmit(event) {
    if (['NumpadEnter', 'Enter'].includes(event.code)) {
      event.preventDefault();
    }
  }

  #formatAddressComponents(addressComponents) {
    const data = {}

    addressComponents.forEach((component) => {
      const type = component.types[0]
      data[type] = component.short_name
    })

    return data
  }
}
