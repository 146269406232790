import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  reindex() {
    const sortableContainer = this.element.querySelector('.sortable-container');
    const children = Array.from(sortableContainer.children)
    const forms = [];

    children.forEach((element) => {
      if (!element.hasAttribute('data-sortable-update-url')) {
        forms.push(element);
        if (!element.classList.contains('filtered')) {
          element.classList.add('filtered');
        }
      }
    })

    forms.forEach((element) => {
      element.parentElement.appendChild(element);
    })

    const updatedSortable = this.element.querySelector('.sortable-container')
    const updatedChildren = Array.from(updatedSortable.children)
    updatedChildren.forEach((element, index) => {
      const slot = index + 1;
      element.id = `slot_${slot}`;
      const input = element.querySelector('input[name="press_release_image[position]"]')
      if (input) {
        input.value = slot;
      } else {
        const deleteButton = element.querySelector('.btn.btn-light')
        deleteButton.href = `${deleteButton.pathname}?slot=${slot}`;
      }
    });
  }

  itemTargetConnected() {
    this.reindex();
  }
}
