import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['modal'];
  static values = { identifier: String };

  connect() {
    console.log("Connected Stimulus!", this.identifierValue);

    this.openModalButton = document.querySelector(`#open${this.identifierValue}ModalButton`);
    this.modalTarget.id = `${this.identifierValue}Modal`;

    this.openModalButton.addEventListener('click', this.openModal.bind(this));
  }

  openModal() {
    this.modalTarget.classList.remove('hidden');
    document.body.classList.add('overflow-hidden');
  }

  closeModal() {
    this.modalTarget.classList.add('hidden');
    document.body.classList.remove('overflow-hidden');
  }
}
