import { Controller } from "@hotwired/stimulus"
import * as bootstrap from 'bootstrap'
import Dropzone from '@crespire/dropzone'

export default class extends Controller {
  connect() {
    try {
      this.dropzone = new Dropzone('#logo-dropzone', this.dzOptions)
    } catch {
      console.error(`Error loading ${this.element.id} dropzone`)
    }
  }

  get dzOptions() {
    return {
      maxFilesize: 20, // MB
      maxFiles: 1,
      parallelUploads: 1,
      acceptedFiles: "image/jpeg, image/png",
      resizeMimeType: 'image/jpeg',
      resizeHeight: 1000,
      hiddenInputContainer: this.element,
      disablePreviews: true,
      renameFile: function (file) {
        return file.name.split('.').slice(0, -1).concat('jpg').join('.')
      },
      init: function () {
        this.on('addedfile', (file) => {
          const target = this.element.querySelector('div.text-center');
          target.innerHTML = `
              <div class="spinner-border text-primary-600" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            `
        });
        this.on('success', (file, response) => {
          // Update display - unable to use targets due to controller scope
          const addLogoBtn = document.querySelector('.add-logo-btn');
          const logoImg = document.querySelector('.logo-img');
          const bgLogo = document.querySelector('.logo-container')
          const logoModalEl = document.getElementById('logoUploadModal')
          const logoUploadModal = bootstrap.Modal.getInstance(logoModalEl);

          addLogoBtn.classList.add('d-none');
          bgLogo.classList.remove('bg-logo', 'p-5')
          bgLogo.classList.add('logo')
          logoUploadModal.hide();

          logoImg.src = response.url;
          logoImg.classList.add('img-fluid', 'rounded');

          // Remove uploaded file to prepare for re-upload
          const logoDropzone = Dropzone.forElement('#logo-dropzone');
          logoDropzone.removeFile(file);
        });
        // Get rid of the default message div
        const msg = this.element.querySelector('.dz-default');
        msg.remove();
      },
      queuecomplete: function() {
        const prompt = this.element.querySelector('div.text-center');
        const rejectedFiles = this.getRejectedFiles();
        if (rejectedFiles.length > 0) {
          prompt.innerHTML = `
            <span class="text-error-500">The logo didn't meet our requirements.<br>Maximum size is 5MB and must be a JPG or PNG.<br>Please try another file.</span>
          `
        } else {
          prompt.innerHTML = `
            Drag and drop your file here <br> or <br> <span class="text-primary-600">click to browse your files</span>
          `
        }
      }
    }
  }
}
