import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["blurSection", "button", "cardAddButton"]

    connect() {
        this.cardAddButtonTargets.forEach((cardAddButton) => {
            if (cardAddButton.textContent.includes("Added")) {
                this.enableSections();
            } else {
                this.buttonTarget.addEventListener("click", this.enableSections.bind(this));
                cardAddButton.addEventListener("click", this.enableSections.bind(this));
            }
        });
    }

    enableSections(event) {
        this.blurSectionTargets.forEach(section => {
            section.remove();
        });

        // Now hides the button
        this.buttonTarget.classList.add("hidden");
    }
}
