import { Controller } from "@hotwired/stimulus"
import * as bootstrap from 'bootstrap';

export default class extends Controller {
  static outlets = ['details']
  static targets = ['input']

  detailsOutletConnected(_, element) {
    const infoArea = element.querySelector('.collapse.show');
    this.collapseButton = element.querySelector('a.text-caption-two');
    this.collapse = bootstrap.Collapse.getOrCreateInstance(infoArea);
  }

  detailsOutletDisconnected(_, element) {
    this.collapse = undefined;
  }

  closeDetails() {
    if (this.collapse) {
      this.collapseButton.textContent = 'Show section'
      this.collapse.hide();
    }
  }

  updatePlaceholder({ detail: { placeholder }}) {
    if (this.hasInputTarget) {
      this.inputTarget.placeholder = placeholder;
    }
  }
}
