import { Controller } from "@hotwired/stimulus"
import { patch } from '@rails/request.js'

export default class extends Controller {
  static values = {
    msDelay: { type: Number, default: 250 },
    url: String,
  }

  static targets = ['city', 'state']

  initialize() {
    this.save = this.save.bind(this)
    if (!this.hasUrlValue) {
      console.error('No autosave found URL')
    }
  }

  connect() {
    if (this.msDelayValue > 0) {
      this.save = this.debounce(this.save, this.msDelayValue)
    }
  }

  debounce(fn, wait) {
    let timeoutId = null

    return function(context = this) {
      const args = Array.from(arguments)
      const params = args.map(arg => arg.params)

      const callback = () => {
        args.forEach((arg, index) => (arg.params = params[index]))
        return fn.apply(context, args)
      }

      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      timeoutId = setTimeout(callback, wait)
    }
  }

  async saveHidden(event) {
    const data = new FormData();
    data.set(this.cityTarget.name, this.cityTarget.value)
    data.set(this.stateTarget.name, this.stateTarget.value)
    const response = await patch(this.urlValue, { body: data, responseKind: 'turbo-stream' })
  }

  async save(event) {
    const fieldname = (string) => {

      switch (true) {
        case string.includes('announcement') === true:
          return 'press_release[announcement]'
        case string.includes('spokesperson_first_name') === true:
          return 'press_release[spokesperson_first_name]'
        case string.includes('spokesperson_last_name') === true:
          return 'press_release[spokesperson_last_name]'
        case string.includes('spokesperson_title') === true:
          return 'press_release[spokesperson_title]'
        case string.includes('body') === true:
          return 'press_release[body]'
        case string.includes('title') === true:
          return 'press_release[title]'
        case string.includes('use_subheader') === true:
          return 'press_release[use_subheader]'
        case string.includes('subheader') === true:
          return 'press_release[subheader]'
        case string.includes('use_quote') === true:
          return 'press_release[use_quote]'
        case string.includes('organization_id') === true:
          return 'press_release[organization_id]'
        case string.includes('use_case_id') === true:
          return 'press_release[use_case_id]'
        case string.includes('scheduled_at') === true:
          return 'press_release[scheduled_at]'
        case string.includes('contact_email') === true:
          return 'press_release[contact_email]'
        case string.includes('contact_first_name') === true:
          return 'press_release[contact_first_name]'
        case string.includes('contact_last_name') === true:
          return 'press_release[contact_last_name]'
        case string.includes('contact_phone') === true:
          return 'press_release[contact_phone]'
        case string.includes('country') === true:
          return 'press_release[country]'
        case string.includes('location') === true:
          return 'press_release[location]'
        default:
          return null;
      }
    }
    const feedback_element = document.querySelector('turbo-frame#autosave_result');
    if (feedback_element) {
      feedback_element.innerHTML =
        `
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 15a4.5 4.5 0 0 0 4.5 4.5H18a3.75 3.75 0 0 0 1.332-7.257 3 3 0 0 0-3.758-3.848 5.25 5.25 0 0 0-10.233 2.33A4.502 4.502 0 0 0 2.25 15Z" />
          </svg>

        `
      feedback_element.setAttribute('working', '')
    }
    // Build form data to submit
    const data = new FormData();
    const input_element = event.target;
    const name = fieldname(input_element.id);
    if (name === null) {
      console.error('Autosave Controller: Attribute not recognized.');
      return;
    }
    let value = input_element.value;
    if (name.includes('body')) {
      value = document.getElementById(input_element.getAttribute('input')).value
    }
    if (name.includes('quote')) {
      value = input_element.checked ? 1 : 0
    }
    data.set(name, value)
    const response = await patch(this.urlValue, { body: data, responseKind: 'turbo-stream' })
    setTimeout(() => {
      feedback_element.innerHTML =
        `
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 15a4.5 4.5 0 0 0 4.5 4.5H18a3.75 3.75 0 0 0 1.332-7.257 3 3 0 0 0-3.758-3.848 5.25 5.25 0 0 0-10.233 2.33A4.502 4.502 0 0 0 2.25 15Z" />
          </svg>
        `
      feedback_element.title = `Last autosaved at ${new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
    }, 5000)

    if (name.includes('organization_id') || name.includes('use_case_id')) {
      // Remove URL query parameters
      const url = new URL(window.location.href);
      url.search = '';

      // Reload to the resulting URL
      window.location.href = url.toString();
    }
  }
}
