import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "drawer", "certify", "checkbox", "errorMsg" ];
  static classes = [ "bg", "error", "hidden" ];

  openDrawer() {
    this.drawerTarget.classList.remove(this.hiddenClass);
  }

  closeDrawer() {
    this.checkboxTarget.checked = false;
    this.#resetDrawerStyles();
    this.drawerTarget.classList.add(this.hiddenClass);
  }

  update() {
    this.checkboxTarget.checked = !this.checkboxTarget.checked;

    if (this.checkboxTarget.checked) {
      this.#resetDrawerStyles();
    }
  }

  next(event) {
    if (!this.checkboxTarget.checked) {
      event.preventDefault();
      this.certifyTarget.classList.remove(this.bgClass);
      this.certifyTarget.classList.add(this.errorClass);
      this.errorMsgTarget.classList.remove(this.hiddenClass);
    } else {
      this.#resetDrawerStyles();
    }    
  }

  #resetDrawerStyles() {
    this.certifyTarget.classList.add(this.bgClass);
    this.certifyTarget.classList.remove(this.errorClass);
    this.errorMsgTarget.classList.add(this.hiddenClass);
  }
}
