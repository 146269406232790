import { Controller } from "@hotwired/stimulus"

// connects to data-controller="details"
export default class extends Controller {
  static targets = ['toggle']
  static values = {
    hidden: { type: String, default: 'Show' },
    shown: { type: String, default: 'Hide' }
  }

  toggled(event) {
    if (this.toggleTarget.getAttribute('aria-expanded') === 'false') {
      this.toggleTarget.textContent = this.hiddenValue;
    } else {
      this.toggleTarget.textContent = this.shownValue;
    }
  }
}
