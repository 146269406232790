import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.form = document.getElementById('new_user');
    this.passwordInput = document.getElementById('user_password');
  }

  validate(event) {
    const element = event.target;
    if (element.checkValidity()) {
      element.removeAttribute('aria-invalid');
      element.parentElement.classList.remove('field_with_errors');
    } else {
      element.setAttribute('aria-invalid', true);
      element.parentElement.classList.add('field_with_errors');
    }
  }

  matchPasswords(event) {
    const element = event.target;

    if (element.value == this.passwordInput?.value) {
      element.removeAttribute('aria-invalid');
      element.parentElement.classList.remove('field_with_errors');
      element.setCustomValidity("");
    } else {
      element.setAttribute('aria-invalid', true);
      element.parentElement.classList.add('field_with_errors');
      element.setCustomValidity("Passwords must match.");
      element.checkValidity();
    }
  }
}
