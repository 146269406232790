import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    placeholder: String
  }

  connect() {
    this.dispatch('connect', { detail: { placeholder: this.placeholderValue }})
  }
}
