import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    connect() {
        const loadingMessage = document.getElementById('loading-message');

        // define an array of messages to display while the page is loading
        const messages = [
            'The first recorded PR campaign was launched by Julius Caesar in 44 BC when he wrote and distributed a report about his military victories.',
            'The first-ever publicity stunt was pulled by P. T. Barnum in 1835 when he convinced a woman to pose as George Washington’s 161-year-old nursemaid.',
            '“If I was down to my last dollar, I would spend it on public relations.” —Bill Gates, co-founder of Microsoft',
            '“The art of PR is in the finding of stories within your brand and then bringing those stories to life.” —Richard Branson, founder of Virgin Group',
            '“Public relations are a key element of any successful business strategy because they help to build and maintain the reputation of the company and its products or services.” —Jack Welch, former CEO of General Electric',
            '“Marketing is an investment, not a cost.” —Sir Martin Sorrell, CEO of WPP, the world’s largest advertising company',
            '68% of businesses report raised brand visibility because of publishing a press release.',
            'Press releases have a long shelf life: 30% of businesses reported that a journalist contacted them about a release they’ve sent over the wire several months—or even a year or more—after they’ve sent it.',
            '“It takes 20 years to build a reputation and five minutes to ruin it. If you think about that, you\'ll do things differently.” —Warren Buffett, chairman and CEO of Berkshire Hathaway',
            'Over 30% of EZ Newswire announcements come from businesses owned and operated by the BIPOC community.',
            'Nearly 50% of EZ Newswire announcements come from businesses owned and operated by women.',
            'From Main Street to Wall Street: EZ Newswire has supported business announcements in 785 towns and cities across the U.S.',
            'Not all AI is created the same: Our technology is informed by decades of experience from our team of PR experts, machine learning specialists, and professional writers.',
        ];

        function shuffleArray(array) {
            let currentIndex = array.length, temporaryValue, randomIndex;

            // While there remain elements to shuffle...
            while (0 !== currentIndex) {

                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex -= 1;

                // And swap it with the current element.
                temporaryValue = array[currentIndex];
                array[currentIndex] = array[randomIndex];
                array[randomIndex] = temporaryValue;
            }

            return array;
        }

        // Shuffle messages
        const shuffledMessages = shuffleArray(messages);

        // initialize an index to keep track of the current message
        let currentMessageIndex = 0;

        // define a function to cycle through the messages
        function updateMessage() {
            // set the message to a random one and gradually fade it in
            loadingMessage.style.opacity = 0;
            const currentMessage = shuffledMessages[currentMessageIndex];
            loadingMessage.textContent = currentMessage;
            setTimeout(() => {
                loadingMessage.style.opacity = 1;
            }, 100);

            // set a timeout to gradually fade out the message after 3.5 seconds and then call this function again
            setTimeout(() => {
                loadingMessage.style.opacity = 0;
                // increment the message index, if it's reached the end of the array, reset it
                currentMessageIndex = (currentMessageIndex + 1) % shuffledMessages.length;
                setTimeout(updateMessage, 500);
            }, 5500);
        }

        // start cycling through the messages
        updateMessage();
    }
}
