import { Controller } from "@hotwired/stimulus"
import { patch } from '@rails/request.js'

// Connects to data-controller="survey"
export default class extends Controller {
  static targets = ['popup', 'submit', 'comment', 'form', 'saved'];
  static values = {
    msDelay: { type: Number, default: 1000 },
    url: String,
  }

  initialize() {
    this.save = this.save.bind(this)
  }

  connect() {
    if (this.msDelayValue > 0) {
      this.save = this.debounce(this.save, this.msDelayValue)
    }
  }

  close() {
    if (this.hasPopupTarget) {
      this.popupTarget.classList.add('close')
    }
    if (this.hasFormTarget && !this.hasSavedTarget) {
      const closed = document.createElement('input');
      closed.type = 'hidden'
      closed.name = 'survey[close]'
      closed.value = true
      this.formTarget.appendChild(closed);
      this.formTarget.submit();
    }
  }

  submit() {
    if (this.hasSubmitTarget) {
      this.submitTarget.disabled = true;
      this.submitTarget.value = 'Thanks!'
      this.close();
    }
  }

  debounce(fn, wait) {
    let timeoutId = null

    return function(context = this) {
      const args = Array.from(arguments)
      const params = args.map(arg => arg.params)

      const callback = () => {
        args.forEach((arg, index) => (arg.params = params[index]))
        return fn.apply(context, args)
      }

      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      timeoutId = setTimeout(callback, wait)
    }
  }

  async save(event) {
    if (this.hasCommentTarget) {
      // Build form data to submit
      const data = new FormData();
      const input_element = event.target;
      let value = input_element.value;
      data.set('survey[comment]', value)
      const response = await patch(this.commentTarget.action, { body: data, responseKind: 'turbo-stream' })
    } else {
      console.log('No form target, unable to autosave.');
    }
  }
}
