import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  update() {
    const editorOrg = document.getElementById('press_release_organization_id');
    const editorContactName = document.getElementById('sidebar_contact_name')?.textContent;
    const editorContactEmail = document.getElementById('sidebar_contact_email')?.textContent;
    const editorContactPhone = document.getElementById('sidebar_contact_phone')?.textContent;

    // Safe navigate as we eager load via Turbo
    const previewContactName = document.getElementById('preview_contact_name');
    if (previewContactName) {
      previewContactName.textContent = editorContactName;
    }
    const previewContactEmail = document.getElementById('preview_contact_email');
    if (previewContactEmail) {
      previewContactEmail.textContent = editorContactEmail;
    }
    const previewContactPhone = document.getElementById('preview_contact_phone');
    if (previewContactPhone) {
      previewContactPhone.textContent = editorContactPhone;
    }

    // Reload preview
    const galleryFrame = document.getElementById('press_release_gallery');
    galleryFrame.reload();

    const orgFrame = document.getElementById('org_show');
    if (editorOrg) {
      const press_release_id = orgFrame.src.split('?').at(-1)
      orgFrame.setAttribute('src', `/organizations/${editorOrg.value}?${press_release_id}`);
    }
    orgFrame.reload();

    const prFrame = document.getElementById('press-release-preview');
    prFrame.reload();

    // Dispatch to external links controller
    this.dispatch('update');
  }
}
