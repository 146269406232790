import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { tooltip_classes: String }
  static targets = ["tooltip"]

  connect() {
    console.log("Hello, Stimulus!", this.element);

    this.appendTooltipClasses();
  }

  appendTooltipClasses() {
    if(!this.hasTooltipTarget) return;

    this.tooltipClassesValue.split(" ").forEach((tooltipClass) => this.tooltipTarget.classList.add(tooltipClass));
  }
}
