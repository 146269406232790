import {Controller} from "@hotwired/stimulus"

const draftButton = document.getElementById('press_release_partial');
const intentField = document.getElementById('press_release_intent')
const form = document.getElementById('new_press_release_form');
const backElement = document.getElementById('confirmReleaseExitModal');

export default class extends Controller {
  connect() {
    this.lastClicked = null;
    document.addEventListener('click', (event) => {
      this.lastClicked = event.target;
    });

    if (draftButton) {
      draftButton.addEventListener('click', (event) => {
        event.preventDefault();
        form.submit();
      })
    }
  }

  stopClose(event) {
    if (
      backElement?.classList.contains('show')
      || this.lastClicked?.classList.contains('dropdown-item')
      || this.lastClicked?.dataset?.name == 'intent'
      || this.lastClicked?.dataset?.name == 'save_org'
      || this.lastClicked?.dataset?.name == 'switch_org'
      || this.lastClicked?.dataset?.name == 'own_draft'
    ) {
      return;
    }
    event.preventDefault();
    event.returnValue = 'Are you sure?';
  }
}
