import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="generate-timeout"
export default class extends Controller {
  static values = {
    delaySeconds: { type: Number, default: 60 },
    stream: { type: Boolean, default: false }
  }

  connect() {
    setTimeout(() => {
      const flash = document.getElementById('flash');
      let msg = null;
      if (this.streamValue) {
        msg = `
          This is taking longer than expected. If nothing happens soon, you can <a href="javascript:window.location.reload()">reload</a> and try again.
        `
      } else {
        msg = `
          This is taking longer than expected. If you don't get redirected soon, you can try again by going to the <a data-turbo="false" href="/dashboard">dashboard</a>.
        `
      }
      flash.innerHTML = `
        <div class="row flash">
          <div class="col text-error-500 bg-error-000">
            <svg class="stroke-error-500" width="24px" height="24px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M12 7v6M12 17.01l.01-.011M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
            <span>
              ${msg}<br>
              If the error persists, please contact support: <a href="mailto:hello@eznewswire.com">hello@eznewswire.com</a>
            </span>
          </div>
        </div>
       `;
    }, (this.delaySecondsValue * 1000)) // 60 second delay
  }
}
