import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["useQuote", "spokespersonFirstName", "spokespersonLastName", "spokespersonTitle"]

  connect() {
    console.log("Hello, Stimulus!", this.element);

    this.toggleSpokespersonFields();
  }

  toggleSpokespersonFields() {
    const required = this.useQuoteTarget.checked;
    this.spokespersonFirstNameTarget.required = required;
    this.spokespersonLastNameTarget.required = required;
    this.spokespersonTitleTarget.required = required;

    if (!required) {
        this.spokespersonFirstNameTarget.value = "";
        this.spokespersonLastNameTarget.value = "";
        this.spokespersonTitleTarget.value = "";
    }
  }
}
