import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['button', 'title', 'location', 'editor', 'subheader', 'bodyError', 'titleError', 'subheaderError', 'locationError'];
  static values = {
    skipValidate: Boolean
  };

  connect() {
    this.valid = new Set();
    this.fireValidate();
  }

  fireValidate() {
    if (this.skipValidateValue === true) {
      return;
    }

    this.validateTitle();
    this.validateSubheader();
    this.validateLocation();
    this.validateBody();
  }

  validateTitle() {
    if (this.titleTarget.checkValidity()) {
      this.valid.add('title');
      this.titleTarget.parentElement.classList.remove('field_with_errors')
      this.titleErrorTarget.textContent = ''
    } else {
      this.valid.delete('title');
      this.titleTarget.parentElement.classList.add('field_with_errors')
      this.titleTarget.classList.add('border')
      this.titleTarget.classList.add('border-red-500')
      this.titleErrorTarget.textContent = 'Must be at least 5 characters long.'
    }

    this.changeButtons();
  }

  validateSubheader() {
    if (this.subheaderTarget.value.length == 0) {
      this.valid.add('subheader')
      return;
    }

    if (this.subheaderTarget.checkValidity()) {
      this.valid.add('subheader');
      this.subheaderTarget.parentElement.classList.remove('field_with_errors')
      this.subheaderErrorTarget.textContent = ''
    } else {
      this.valid.delete('subheader');
      this.subheaderTarget.parentElement.classList.add('field_with_errors')
      this.subheaderTarget.classList.add('border')
      this.subheaderTarget.classList.add('border-red-500')
      this.subheaderErrorTarget.textContent = 'Must be at least 5 characters long.'
    }

    this.changeButtons();
  }

  validateBody() {
    const atLeastOneValidChild = (node) => {
      for (const child of node.childNodes) {
        if (child.textContent.length > 10) {
          return true;
        }
      }
      return false;
    }

    if (atLeastOneValidChild(this.editorTarget)) {
      this.valid.add('body');
      this.bodyErrorTarget.textContent = '';
    } else {
      this.valid.delete('body');
      this.bodyErrorTarget.textContent = 'Must be at least 10 characers long.'
    }
    this.changeButtons()
  }

  validateLocation() {
    if (this.locationTarget.checkValidity()) {
      this.valid.add('location');
      this.locationTarget.parentElement.classList.remove('field_with_errors')
      this.locationErrorTarget.textContent = '';
      this.dispatch('validateLocation')
    } else {
      this.valid.delete('location')
      this.locationTarget.parentElement.classList.add('field_with_errors')
      this.locationErrorTarget.textContent = "Can't be blank"
    }

    this.changeButtons();
  }

  changeButtons() {
    if (this.valid.size == 4) {
      this.titleErrorTarget.textContent = '';
      this.subheaderErrorTarget.textContent = '';
      this.locationErrorTarget.textContent = '';
      this.bodyErrorTarget.textContent = '';
      this.buttonTargets.forEach(button => button.classList.remove('disabled'));
    } else {
      this.buttonTargets.forEach(button => button.classList.add('disabled'));
    }
  }
}
