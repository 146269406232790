import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="page-redirect"
export default class extends Controller {
  static values = { path: String, timeoutSeconds: { type: Number, default: 30 } };

  connect() {
    setTimeout(() => {
      Turbo.visit(this.pathValue);
    }, this.timeoutSecondsValue * 1000);
  }
}
