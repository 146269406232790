import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["imagesContainer", "imageItem", "uploadField"];

  connect() {
    console.log("Hello, Stimulus!", this.element);

    this.uploadFieldTarget.addEventListener("change", this.upload.bind(this));
  }

  onclick(event) {
    event.preventDefault();

    this.uploadFieldTarget.click();
  }

  upload(event) {
    event.preventDefault();

    const form = this.uploadFieldTarget.closest("form");

    form.submit();
  }
}
