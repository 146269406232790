import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { press_release_id: String };

  connect() {
    console.log("Hello, Stimulus!", this.element);

    this.tooltip = document.getElementById("tooltip");
  }

  copy(event) {
    event.preventDefault();

    const url = this.data.get("link")

    navigator.clipboard.writeText(url).then(() => {
      this.displayTooltip()

      setTimeout(() => {
        this.hideTooltip()
      }, 2000)
    }).catch((error) => {
      console.error("Failed to copy to clipboard", error);
    });
  }

  displayTooltip() {
    this.tooltip.classList.remove("hidden")
    this.tooltip.classList.add("flex")
  }

  hideTooltip() {
    this.tooltip.classList.add("hidden")
    this.tooltip.classList.remove("flex")
  }
}
