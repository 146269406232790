import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="drag-drop"
export default class extends Controller {
  static values = { 
    maxSize: Number,
    acceptedTypes: String,
    multiple: Boolean,
    default: String,
    fieldName: String,
    controller: String,
    path: String }

  static targets = [ "input", "preview", "dropzone", "wrapper", "logo" ]

  #common_header = {
    headers: {
      'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
      'Accept': 'application/json'
    },
    credentials: 'include'
  }
  
  connect() {
    this.dropzoneTarget.addEventListener('dragover', (event) => this.dragOver(event))
    this.dropzoneTarget.addEventListener('drop', (event) => this.drop(event))
    this.dropzoneTarget.addEventListener('click', (event) => this.onclick(event))

    this.startDragDrop()
  }

  startDragDrop() {
    if(this.defaultValue) {
      this.displayPreview(this.defaultValue)
    } else {
      this.wrapperTarget.style.display = 'flex';
    }
  }

  dragOver(event) {
    event.preventDefault()
  }

  drop(event){
    event.preventDefault()
    event.stopPropagation()

    const files = event.dataTransfer.files
    if(files.length > 0) {
      this.multipleValue ? this.validateAndAssignFiles(files) : this.validateAndAssignFile(files[0])
    }
  }

  displayPreview(dataUrl) {
    this.dropzoneTarget.style.display = 'none';
    this.previewTarget.style.display = 'flex';

    if (this.previewTarget) {
      this.logoTarget.src = dataUrl;
      this.logoTarget.style.display = 'block';
    } else {
      const newPreview = document.createElement('img');
      newPreview.class = 'drag-drop-image';
      newPreview.src = dataUrl;
      newPreview.style.display = 'block';
      this.previewTarget.appendChild(newPreview);
    }
  }

  loading(isLoading = false){
    const overlay = this.dropzoneTarget.querySelector('.drag-drop-overlay')
    const dragDropEmpty = this.dropzoneTarget.querySelector('.drag-drop-empty')

    if(isLoading) {
      dragDropEmpty.style.filter = 'blur(1px)'
      overlay.style.opacity = 1
    } else {
      dragDropEmpty.style.filter = 'none'
      overlay.style.opacity = 0
    }
  }

  uploadFile(file) {
    const formData = new FormData();
    formData.append(`${this.controllerValue}[${this.fieldNameValue}]`, file);

    this.loading(true)

    fetch(this.pathValue, {
      ...this.#common_header,
      method: 'POST',
      body: formData,
      headers: {
        ...this.#common_header.headers,
      },
    })
    .then(response => response.json())
    .then(({url}) => {
      this.displayPreview(url);
    })
    .catch((error) => {
      console.error('Error:', error);
    })
    .finally(() => {
      this.loading(false)
    });
  }

  detachFile() {
    fetch(this.pathValue, {
      ...this.#common_header,
      method: 'DELETE',
      headers: {
        ...this.#common_header.headers,
        'Content-Type': 'application/json'
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(() => this.resetFiles())
    .catch((error) => {
      console.error('Error:', error);
    });
  }

  resetFiles() {
    // Clear the input
    this.inputTarget.value = "";
  
    // Hide the image preview and overlay
    if (this.previewTarget) {
      this.logoTarget.style.display = 'none';
      this.logoTarget.src = '';
    }

    
  
    // Reset the drag-drop area styles
    this.dropzoneTarget.style.display = 'flex';
    this.wrapperTarget.style.display = 'flex';
    this.previewTarget.style.display = 'none';
  }

  validateAndAssignFiles(files) {
    const dataTransfer = new DataTransfer()
    const validFiles = Array.from(files).filter(file => this.isFileAccepted(file))

    validFiles.forEach(file => dataTransfer.items.add(file))

    if(dataTransfer.items.length === files.length) {
      this.inputTarget.files = dataTransfer.files    
    } else {
      alert("One or more files are not permitted")
    }
  }

  validateAndAssignFile(file) {
    const dataTransfer = new DataTransfer()

    if(this.isFileAccepted(file)) {
      dataTransfer.items.add(file)

      this.inputTarget.files = dataTransfer.files
      
      this.uploadFile(file)
    } else {
      alert("File is not permitted")
    }
  }

  isFileAccepted(file) {
    const acceptedTypes = this.acceptedTypesValue.split(',').map(type => type.trim())
    
    return acceptedTypes.includes(file.type) && file.size <= this.maxSizeValue
  }

  fileSelected(element = null) {
    if(this.multipleValue) {
      this.validateAndAssignFiles(element ? element.files : this.inputTarget.files)
    } else {
      this.validateAndAssignFile(element ? element.files[0] : this.inputTarget.files[0])
    }
  }

  // On inputTarget the file chooser dialog can only be shown with a user activation.
  // This is why we create a temporary input element to show the file chooser dialog.
  create_tmp_element() {
    const input = document.createElement('input')

    input.type = 'file'
    input.accept = this.acceptedTypesValue
    input.multiple = this.multipleValue
    input.style.display = 'none'

    input.onchange = () => this.fileSelected(input)
    input.click()

    input.remove()
  }

  onclick(event) {
    event.preventDefault()

    this.create_tmp_element()
  }
}
