import { Controller } from "@hotwired/stimulus"

const editingText = 'Save';
const displayText = 'Click to edit'

// Connects to data-controller="onboarding-confirm-generation"
export default class extends Controller {
  static targets = ['input', 'toggle', 'hidden', 'error'];

  toggleEditable() {
    const valid = this.#validate();
    if (valid) {
      if (this.inputTarget.isContentEditable) {
        this.inputTarget.removeAttribute('contenteditable');
        this.toggleTarget.textContent = displayText;
      } else {
        this.inputTarget.setAttribute('contenteditable', 'plaintext-only');
        this.inputTarget.textContent = this.inputTarget.textContent.trim();
        this.toggleTarget.textContent = editingText;
      }
    }
  }

  capture(event) {
    this.hiddenTarget.value = event.target.textContent.trim();
  }


  submit(event) {
    if (!this.#validate()) {
      event.preventDefault();
    }
  }

  #validate() {
    if (this.inputTarget.textContent.trim().length < 10) {
      this.errorTarget.classList.remove('d-none');
      this.errorTarget.classList.add('d-flex');
      return false;
    } else {
      this.errorTarget.classList.add('d-none');
      this.errorTarget.classList.remove('d-flex');
      return true;
    } 
  }
}
