import { Controller } from '@hotwired/stimulus'
import Swiper, { Navigation, Pagination } from 'swiper'

export default class extends Controller {
  connect() {
    this.swiper = new Swiper(this.element, {
      modules: [Navigation, Pagination],
      cssMode: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      pagination: {
        el: '.swiper-pagination'
      }
    })
    refreshFsLightbox();
  }

  disconnect() {
    this.swiper.destroy()
    this.swiper = undefined
  }
}
