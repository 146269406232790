import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    toggle () {
        const myCollapsible = document.getElementById('pressReleaseCollapse')
        const collapseText = document.getElementById('collapse-text')
        const viewText = document.getElementById('view-collapsed-text')

        myCollapsible.addEventListener('shown.bs.collapse', event => {
            viewText.classList.add('d-none')
            collapseText.classList.remove('d-none')
        })

        myCollapsible.addEventListener('hidden.bs.collapse', event => {
            viewText.classList.remove('d-none')
            collapseText.classList.add('d-none')
        })
    }
}
