
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="org-sync"
export default class extends Controller {
  static targets = ['form']

  connect() {
    const source = document.querySelector('.org-source')
    if (source) {
      this.formTarget.value = source.textContent
    }
  }
}
