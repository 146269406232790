import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        const tweetLink = this.element;
        const {dataset: {url}} = tweetLink
        
        tweetLink.addEventListener('click', (event) => {
            event.preventDefault();
            const tweetUrl = `https://twitter.com/intent/tweet?text=I just read the news about ${tweetLink.dataset.business} on EZ Newswire! Check it out: ${encodeURIComponent(url)}`
            window.open(tweetUrl);
        });
    }
}

