import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="onboarding-url"
export default class extends Controller {
  addScheme(event) {
    const element = event.target;

    if (!element.value.includes('http')) {
      element.value = "https://" + element.value;
    }

    if (element.value === 'https://') {
      element.value = '';
    }
  }

  setValue(event) {
    const element = event.target;

    if (element.value.length == 0) {
      element.value = "https://";
    }
  }
}
