import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'icon', 'input2', 'icon2']
  static classes = ['hidden'];

  connect() {
    if (this.hasInputTarget) {
      this.hidden = this.inputTarget.type === 'password'
    }
    this.class = this.hasHiddenClass ? this.hiddenClass : 'd-none'
  }

  toggle(event) {
    event.preventDefault()

    if (this.hasInputTarget) {
      this.inputTarget.type = this.hidden ? 'text' : 'password'
      this.hidden = !this.hidden
    }

    if (this.hasIconTarget) {
      this.iconTargets.forEach(icon => icon.classList.toggle(this.class))
    }
  }

  toggle2(event) {
    event.preventDefault()

    if (this.hasInput2Target) {
      this.input2Target.type = this.hidden ? 'text' : 'password'
      this.hidden = !this.hidden
    }

    if (this.hasIcon2Target) {
      this.icon2Targets.forEach(icon => icon.classList.toggle(this.class))
    }
  }
}
