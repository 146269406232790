import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="timezone"
export default class extends Controller {
  static targets = ['value', 'display']
  
  connect() {
    const dateString = new Date().toLocaleDateString('en-US', {timeZoneName: 'short'});
    const zone = dateString.split(', ').at(-1)
    this.displayTarget.textContent = zone;
    this.valueTarget.value = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
}
