import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

  detach(e) {
    e.preventDefault()
    const detachLogoBtns = document.querySelectorAll('.detach-logo-btn');
    const csrfToken = document.querySelector("[name='csrf-token']").content
    const addLogoBtn = document.querySelector('.add-logo-btn')
    const logoImg = document.querySelector('.logo-img');
    const bgLogo = document.querySelector('.logo-container');

    detachLogoBtns.forEach((btn) => {
      fetch(`/organizations/${btn.dataset.id}/detach_logo`, {
        method: 'DELETE',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken
        },
      }).then(response => response.json())
    })
      
    addLogoBtn.classList.remove('d-none');
    // This is a 1x1 transparent png image to avoid a broken image icon in safari
    logoImg.src = "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";
    bgLogo.classList.remove('logo')
    bgLogo.classList.add('bg-logo', 'p-5')
  }

}
