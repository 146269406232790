import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    shrink: { type: Boolean, default: false }
  }

  initialize() {
    this.autogrow = this.autogrow.bind(this)
  }

  connect() {
    this.element.style.overflow = 'hidden'
    this.originalSize = this.element.style.fontSize;

    this.onResize = this.autogrow
    this.autogrow()

    this.element.addEventListener('input', this.autogrow)
    window.addEventListener('resize', this.onResize)
    this.element.addEventListener('paste', this.handlePaste.bind(this));
  }

  disconnect() {
    window.removeEventListener('resize', this.onResize)
  }

  handlePaste(event) {
    setTimeout(() => this.autogrow(), 0); // Schedule autogrow after the paste event completes
  }

  autogrow() {
    this.element.style.height = 'auto' // Force re-print before calculating the scrollHeight value.
    this.element.style.height = `${this.element.scrollHeight}px`

    if (this.shrinkValue === false) {
      return;
    }

    let newLines = Array.from(this.element.value.matchAll(/\n/g)).length
    if (this.element.value.length > 120 || newLines > 3) {
      this.element.style.fontSize = '1.5rem';
    } else {
      this.element.style.fontSize = this.originalSize;
    }
  }
}

