import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="onboarding-referral"
export default class extends Controller {
  static classes = ['hide', 'show']
  static targets = ['input', 'select', 'source']

  connect() {
    console.log('Connected to onboarding-referral')
    if (this.hasSelectTarget && this.selectTarget.value == 'other') {
      this.sourceTarget.classList.add(this.showClass);
      this.sourceTarget.classList.remove(this.hideClass)
      this.inputTarget.setAttribute('required', true)
    }
  }

  update(event) {
    if (event.target.value == 'other') {
      this.sourceTarget.classList.add(this.showClass);
      this.sourceTarget.classList.remove(this.hideClass);
      this.inputTarget.setAttribute('required', true);
    } else {
      this.sourceTarget.classList.add(this.hideClass);
      this.sourceTarget.classList.remove(this.showClass);
      this.inputTarget.removeAttribute('required');
    }
  }
}
