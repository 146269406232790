import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        const drawer = document.querySelector('.drawer-placement-bottom');
        const openButton = document.getElementById('close-modal');
        const closeButton = document.getElementById('close')


        openButton.addEventListener('click', () => {
            drawer.show()
        })

        closeButton.addEventListener('click', () => drawer.hide());
    }
}
