import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="country-select"
export default class extends Controller {
  static targets = ['select', 'location'];

  update() {
    this.locationTarget.value = '';
    this.dispatch('update', { detail: { content: [`${this.selectTarget.value}`] } })
  }
}
