import { Controller } from "@hotwired/stimulus"
import Dropzone from '@crespire/dropzone'

export default class extends Controller {
  connect() {
    let tokenInput = this.element.querySelector("input[name='authenticity_token']");

    if (!tokenInput) {
      // Optional chaning because, in test, csrf token will be null
      const csrfToken = document.querySelector("[name='csrf-token']")?.content
      tokenInput = document.createElement('input');
      tokenInput.setAttribute('type', 'hidden');
      tokenInput.setAttribute('name', 'authenticity_token');
      tokenInput.setAttribute('autocomplete', 'off');
      tokenInput.setAttribute('value', csrfToken);
      this.element.prepend(tokenInput);
    }
    try {
      this.dropzone = new Dropzone(this.element, this.dzOptions)
      this.dispatch('connect')
    } catch { }
  }

  get dzOptions() {
    return {
      paramName: "press_release_images",
      maxFilesize: 25, //MB
      maxFiles: 1,
      parallelUploads: 1,
      acceptedFiles: 'image/jpeg,image/png',
      resizeMimeType: 'image/jpeg',
      hiddenInputContainer: this.element,
      resizeHeight: 1000,
      disablePreviews: true,
      renameFile: function (file) {
        return file.name.split('.').slice(0, -1).concat('jpg').join('.')
      },
      init: function () {
        this.on('addedfile', (file) => {
          const target = this.element.querySelector('div.d-flex');
          const overlay = this.element.querySelector('div.overlay');
          overlay.classList.add('d-none');
          if (file.size / 1024 / 1024 > 20) {
            target.innerHTML = `
                <p class="text-center">File too large<br>20MB maximum<br>Try another file</p>
              `
            target.classList.add('text-error-500');
            this.removeAllFiles();
          } else {
            target.innerHTML = `
                <div class="spinner-border text-primary-600" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              `
          }
        });
        const msg = this.element.querySelector('.dz-default');
        msg.remove();
      },
    }
  }
}
