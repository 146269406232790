// https://github.com/excid3/esbuild-rails#importing-stimulus-controllers-from-parent-folders-viewcomponents-etc


import { application } from "../javascript/controllers/application"

import controllers from "./**/*_controller.js"

console.log(controllers)
controllers.forEach((controller) => {
    application.register(controller.name, controller.module.default)
})
