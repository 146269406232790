import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button"];

  connect() {
  }

  update(event) {
    let target = event.target;
    if (target.disabled) { return; }

    this.buttonTargets.forEach((button) => {
      if (target == button) {
        button.innerHTML = `Selected <i class="bi bi-check-circle text-white"></i>`;
      } else {
        button.innerHTML = 'Select';
      }
    })
    this.dispatch("update");
  }

  clearButtons() {
    this.buttonTargets.forEach(button => button.innerHTML = 'Select');
  }
}
