import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'
import { patch } from '@rails/request.js'
import * as bootstrap from 'bootstrap'

export default class extends Controller {
  static values = {
    resourceName: String,
    paramName: {
      type: String,
      default: 'position'
    },
    responseKind: {
      type: String,
      default: 'html'
    },
    animation: Number,
    handle: String,
  }

  initialize() {
    this.reindex = this.reindex.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
  }

  connect() {
    this.sortable = new Sortable(this.element, {
      ...this.options,
      direction: 'horizontal',
      dataIdAttr: 'data-position',
      filter: '.filtered'
    });
  }

  disconnect() {
    this.sortable.destroy()
    this.sortable = undefined
  }

  reindex() {
    // Always push forms to back
    const forms = [];
    const children = Array.from(this.element.children)
    children.forEach((element) => {
      if (!element.hasAttribute('data-sortable-update-url')) {
        forms.push(element);
        if (!element.classList.contains('filtered')) {
          element.classList.add('filtered');
        }
      }
    })

    forms.forEach((element) => {
      element.parentElement.appendChild(element);
    })

    children.forEach((element, index) => {
      element.id = `slot_${index + 1}`;
      const input = element.querySelector('input[name="press_release_image[position]"]')
      if (input) { input.value = index + 1; }
    });
  }

  async onUpdate({ item, newIndex }) {
    const children = Array.from(this.element.children)
    this.reindex();
    if (!item?.dataset?.sortableUpdateUrl) return

    const param = this.resourceNameValue ? `${this.resourceNameValue}[${this.paramNameValue}]` : this.paramNameValue
    const data = new FormData()
    data.append(param, newIndex + 1)
    await patch(item.dataset.sortableUpdateUrl, { body: data, responseKind: this.responseKindValue })
  }

  get options() {
    return {
      animation: this.animationValue || 150,
      handle: this.handleValue || undefined,
      onUpdate: this.onUpdate
    }
  }
}

